

























import { mapGetters } from 'vuex';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import CourseStatus from '@/components/home/CourseStatus.vue';

@Component({
  components: { CourseStatus },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class ProgrammeStatus extends Vue {
  @Prop(Object)
  runConcentration!: RunConcentration;

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    const lang = this.$route.params.locale === 'en' ? 'en-GB' : this.$route.params.locale;
    return date.toLocaleDateString(lang);
  }

  get cssClassColor (): string {
    return `course-status--${this.runConcentration.programmeType}`;
  }

  get progress (): number {
    let { startDate, endDate } = this.runConcentration;
    if (!startDate && this.runCourses) {
      startDate = this.runCourses[0].startDate;
    }
    if (!endDate && this.runCourses) {
      endDate = this.runCourses[0].endDate;
    }

    if (!startDate || !endDate) {
      return 0;
    }

    const startDateDate = new Date(startDate);
    const endDateDate = new Date(endDate);
    const now = new Date();
    const percent = ((now.getTime() - startDateDate.getTime()) / (endDateDate.getTime() - startDateDate.getTime())) * 100;
    return Math.min(Math.max(0, percent), 100);
  }

  get progressStyle (): string {
    return `width: ${this.progress ?? 0}%`;
  }

  get localizedNumber (): string {
    return this.progress.toLocaleString('en', { style: 'decimal' });
  }

  get localizedPercent (): string {
    const percent = this.progress / 100;

    return percent.toLocaleString('en', { style: 'percent' });
  }

  get runCourses (): RunCourse[] | undefined {
    const now = new Date();
    return this.runConcentration.courses.filter((course) => {
      const courseStart = course.startDate ? new Date(course.startDate) : 0;
      const courseEnd = course.endDate ? new Date(course.endDate) : 0;
      return courseStart < now.getTime() && courseEnd > now.getTime();
    });
  }
}
