































import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import User from 'common-modules/src/store/User';
import Concentrations from 'common-modules/src/store/Concentrations';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import jwlAnnouncementBox from '@/components/jwlAnnouncementBox.vue';
import ProgrammeStatus from '@/components/home/ProgrammeStatus.vue';

@Component({
  components: {
    ProgrammeStatus,
    jwlAnnouncementBox,
  },
  computed: mapGetters([
    'currentUser',
    'baseConcentrations',
  ]),
})
export default class JwlHome extends Vue {
  currentUser!: User;
  baseConcentrations!: Concentrations;

  get concentrations (): RunConcentration[] {
    return this.baseConcentrations.dataAsFlatArray();
  }
}
