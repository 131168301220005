



























import { mapGetters } from 'vuex';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import { RunUnit } from 'common-modules/src/store/interface/RunUnit';

@Component({
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class CourseStatus extends Vue {
  @Prop(Object)
  runCourse!: RunCourse;

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    const lang = this.$route.params.locale === 'en' ? 'en-GB' : this.$route.params.locale;
    return date.toLocaleDateString(lang);
  }

  get activeUnitIndex (): number {
    if (!this.runCourse) {
      return -1;
    }

    const now = new Date();
    return this.runCourse.units.findIndex((unit) => {
      const unitStart = new Date(unit.startDate);
      const unitEnd = new Date(unit.endDate);
      return unitStart < now && unitEnd > now;
    });
  }

  get activeUnit (): RunUnit | null {
    if (!this.runCourse || this.activeUnitIndex < 0) {
      return null;
    }

    return this.runCourse.units[this.activeUnitIndex];
  }
}
